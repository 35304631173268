import api from "./api";

import { CONFIG_EXPORT_XLSX } from "constants_globals";

class UserAdminServiceClass {
	userSearch(data: any, signal?: AbortSignal) {
		return api
			.post(`/user`, data, {
				signal,
			})
			.then((resp) => resp.data);
	}

	userDetailsFromBC(data: any) {
		return api
			.post(`/user/details-from-base-centrale`, data)
			.then((resp) => resp.data);
	}

	usersAdminExport() {
		return api.get(`/user/export-xlsx`, CONFIG_EXPORT_XLSX);
	}

	usersContractsAdminExport() {
		return api.post(
			`/management/last-contracts-export-xlsx`,
			{},
			CONFIG_EXPORT_XLSX
		);
	}

	userCreateBC(data: any) {
		return api
			.post(`/user/create-from-base-centrale`, data)
			.then((resp) => resp.data);
	}

	usersPermissionsIds(userIds: number[]) {
		return api
			.post(`/user/permissions-by-ids`, {
				ids: userIds,
			})
			.then((resp) => resp.data);
	}

	usersPermissions(id: any, data: any) {
		return api
			.post(`/user/${id}/permissions`, data)
			.then((resp) => resp.data);
	}

	userAdminInformations(id: any) {
		return api.get(`/user/${id}`).then((resp) => resp.data);
	}

	userAdminPatchInformations(id: any, data: any) {
		return api.patch(`/user/${id}`, data).then((resp) => resp.data);
	}

	userAdminDelete(id: any) {
		return api.delete(`/user/${id}`).then((resp) => resp.data);
	}

	userAdminDeactivate(id: any) {
		return api.put(`/user/${id}/disable`).then((resp) => resp.data);
	}

	userAdminActivate(id: any) {
		return api.put(`/user/${id}/enable`).then((resp) => resp.data);
	}

	userAdminMainAgency(id: any, data: any) {
		return api
			.put(`/user/${id}/main-agency`, data)
			.then((resp) => resp.data);
	}

	userAdminSites(id: any, data: any) {
		return api.patch(`/user/${id}/sites`, data).then((resp) => resp.data);
	}

	userAdminLogs(id: any) {
		return api.get(`/user/${id}/log`, CONFIG_EXPORT_XLSX);
	}

	userAdminResetPassword(id: any) {
		return api.post(`/user/${id}/reset-password`).then((resp) => resp.data);
	}

	userAdminGeneratePassword(id: any) {
		return api
			.post(`/user/${id}/generate-password`)
			.then((resp) => resp.data);
	}

	getUserSite = (email?: string, options: object = {}) =>
		api
			.get("/site", {
				params: email
					? {
							_switch_user: `${email}`,
					  }
					: {},
				...options,
			})
			.then((res) => res.data);
}

const UserAdminService = new UserAdminServiceClass();
export default UserAdminService;
